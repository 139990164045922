/**
* 2.5  Breadcrumbs
*/
$breadcrumb-padding-vertical: 8px;
$breadcrumb-padding-horizontal: 15px;
$breadcrumb-color: $gray-light;
$breadcrumb-active-color: $gray-light;
$breadcrumb-separator: "f14f";

.breadcrumbs-custom {
  position: relative;
  z-index: 1;
  padding: 35px 0 40px;
  vertical-align: middle;
  text-align: center;
  
  > .shell {
    position: relative;
    z-index: 2;
  }
}

.breadcrumbs-custom-wrap {
  position: relative;
}

.breadcrumbs-custom-path {
  a {
    display: inline;
    vertical-align: baseline;
    &, &:active, &:focus {
      color: $breadcrumb-color;
    }
    
    &:hover{
      color: $secondary;
    }
  }

  li {
    position: relative;
    display: inline-block;
    vertical-align: baseline;
    margin-right: 32px;
    font-size: 14px;
    line-height: 1.7;
    letter-spacing: $spacing-md;

    &:after {
      content: char-code($breadcrumb-separator);
      font-family: 'Material Design Icons';
      position: absolute;
      top: 53%;
      right: -25px;
      display: inline-block;
      font-style: normal;
      color: $gray-1;
      transform: translateY(-50%);
      font-size: 15px;
    }
  }

  li:last-child {
    padding-right: 0;
    &:after { display: none; }
  }
  
  li.active {
    color: $breadcrumb-active-color;
  }
}

.breadcrumbs-custom__shape {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  max-width: 1600px;
  height: 100%;

  @include media-breakpoint-up(md) {
    max-width: 2200px;
  }

  @include media-breakpoint-up(xl) {
    max-width: none;
  }
}

.breadcrumbs-custom-title {
  position: relative;
  display: inline-block;
  padding: 0 20px;

  @include media-breakpoint-up(md) {
    padding: 0 40px;
    
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      width: 15px;
      height: 15px;
      background: $gray-darker;
    }

    &::before {
      left: -20px;
    }

    &::after {
      right: -16px;
    }
  }
}

.breadcrumbs-custom-subtitle {
  font-size: 20px;
  line-height: 1.3;
}

* + .breadcrumbs-custom-title { margin-top: 12px; }
* + .breadcrumbs-custom-path { margin-top: 20px; }

// Breadcrumbs light
.breadcrumbs-custom-light {
  @extend %context-dark;

  .breadcrumbs-custom-path {
    a {
      display: inline;
      vertical-align: baseline;
      &, &:active, &:focus {
        color: $white;
      }

      &:hover {
        color: $secondary-1;
      }
    }
    li.active {
      color: $white;
    }
  }
}

// breadcrumbs background
.breadcrumbs-background-01 {
  background-image: url(../images/breadcrumbs-background-01.jpg);
  background-attachment: fixed;
  background-size:cover;
}

// Breadcrumbs blog post
.breadcrumbs-blog-post {
  padding-bottom: 20px;
  .breadcrumbs-custom-title {
    max-width: 720px;
    &:before,
    &:after {
      content: none;
    }
  }
  
  .box-inline {
    margin-left: auto;
    margin-right: auto;
  }
}

@include media-breakpoint-down(sm) {
  .breadcrumbs-custom-title {
    font-size: 26px;
  }
}

@include media-breakpoint-up(sm) {
  .breadcrumbs-custom-path {
    > li {
      margin-right: 52px;

      &:after { right: -35px; }
    }
  }
}

@include media-breakpoint-up(md) {
  .breadcrumbs-custom {
    padding: 70px 0 80px;
  }

  .breadcrumbs-custom-subtitle {
    font-size: 26px;
  }

  * + .breadcrumbs-custom-path { margin-top: 50px; }

  .breadcrumbs-blog-post {
    padding-bottom: 40px;
  }
}

@include media-breakpoint-up(xl) {
  .breadcrumbs-custom {
    padding: 120px 0 50px;
  }
  .breadcrumbs-custom-subtitle {
    font-size: 30px;
  }

  .breadcrumbs-custom-path li {
    font-size: 18px;
  }

  .breadcrumbs-custom-svg-var-2 {
    padding-top: 235px;
    padding-bottom: 100px;
  }
}

@include media-breakpoint-up(xxl) {
  .breadcrumbs-blog-post {
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-between;
    height: 100vh;
    max-height: 870px;
    
    * + .breadcrumbs-custom-path { margin-top: auto; }
    * + .breadcrumbs-custom-title { margin-top: 5vh; }
    * + .box-inline { margin-top: 5vh; }
  }
  
  * + .breadcrumbs-custom-path { margin-top: 100px; }
}