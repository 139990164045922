/*
*
* Owl Carousel
* --------------------------------------------------
*/

.owl-carousel .animated {
	-webkit-animation-duration: 1000ms;
	animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
	z-index: 0;
}

.owl-carousel .owl-animated-out {
	z-index: 1;
}

.owl-carousel .fadeOut {
	-webkit-animation-name: fadeOut;
	animation-name: fadeOut;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
	transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel-widget {
	@include media-breakpoint-up(sm) {
		width: calc(100% - 70px);
	}
}

.owl-carousel-widget {
	.owl-dots {
		display: none;
	}
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	/* fix for flashing background */
	-webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
	cursor: pointer;
	user-select: none;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
	display: none;
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	will-change: transform;
}

.owl-carousel .owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}

.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	font: 400 40px/80px 'FontAwesome';
	cursor: pointer;
	z-index: 1;
	transition: scale 100ms ease;
	&:before {
		content: '\f144';
	}
}

.owl-carousel .owl-video-play-icon:hover {
	-webkit-transform: scale(1.3);
	transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
	display: none;
}

.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
}

/*
 * Owl Navigation
 */

.owl-nav {
	&.disabled {
		display: none;
	}
}

.owl-prev, .owl-next {
	position: absolute;
	top: 50%;
	width: 40px;
	height: 40px;
	line-height: 40px;
	font-size: 20px;
	border-radius: 50%;
	text-align: center;
	margin-top: -20px;
	z-index: 10;
	cursor: pointer;
	background: $secondary;
	color: $white;
	transition: .33s;

	&::before {
		position: relative;
		font-family: 'Material Design Icons';
	}

	&:hover {
		background: $primary;
	}
}

.owl-prev {
	left: 0;

	&::before {
		content: '\f14b';
	}
}

.owl-next {
	right: 0;

	&::before {
		content: '\f150';
	}
}

/*
 * Owl Pagination
 */

.owl-dots {
	text-align: center;
	margin-top: 30px;

	&.disabled {
		display: none;
	}
}

.owl-dot {
	position: relative;
	display: inline-block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	text-align: center;
	outline: none;
	cursor: pointer;
	background-color: $gray-1;
	transition: .25s;

	&:hover,
	&:focus {
		background-color: $primary;
	}

	&.active {
		background-color: $primary;
	}

	& + & {
		margin-left: 20px;
	}

	&:only-child {
		display: none !important;
	}
}

.owl-carousel.owl-dots-secondary {
	.owl-dot {
		border-color: $gray-2;

		&:hover,
		&.active {
			background-color: $secondary;
		}
	}
}

.owl-carousel.owl-carousel-light-dots {
	.owl-dot {
		border-color: $white;

		&:hover,
		&:focus {
			background-color: $white;
		}

		&.active {
			background-color: $white;
		}
	}
}

.owl-carousel {
	figure img {
		width: 100%;
	}
}

/**
* Owl Carousel Pricing
*/
.owl-carousel-pricing {
}

.owl-carousel-team {
	@include media-breakpoint-up(xxl) {
		.owl-dots { display: none; }
		.owl-nav { display: none; }

		[data-x-mode="true"] & {
			.owl-dots { display: block; }
		}
	}
}

.owl-carousel-inset {
	.owl-stage-outer {
		padding: 15px 15px 15px 0;
	}
}

/**
* Owl layout 1
*/
.owl-layout-1 {

	@include media-breakpoint-down(md) {
		.owl-nav {
			display: none !important;
		}
	}

	@include media-breakpoint-up(lg) {
		padding: 0 50px;
		.owl-dots {
			display: none !important;
		}
	}

	@include media-breakpoint-up(xl) {
		padding: 0 100px;
	}

	@include media-breakpoint-up(xxl) {
		padding: 0 140px;
	}
}

@include media-breakpoint-up(xxl) {
	* + .owl-layout-1 {
		margin-top: 90px;
	}
}

/**
* Owl layout 2
*/
.owl-layout-2 {
	@include media-breakpoint-up(md) {
		* + & {
			margin-top: 55px;
		}

		.owl-dots {
			@include group(32px, 5px);
		}

		.owl-dot {
			margin-top: 30px;
		}
	}
}

/**
* Owl layout 3
*/
.owl-layout-3 {
	.owl-prev {
		left: 40px;
	}

	.owl-next {
		right: 40px;
	}
}

/**
* Owl layout 4
*/
.owl-layout-4 {
	.owl-stage {
		@include display-flex;
		@include flex-direction(row);
		@include align-items(center);
	}
	@include media-breakpoint-up(md) {
		* + & {
			margin-top: 55px;
		}

		.owl-dots {
			margin-top: 0;
			@include group(32px, 5px);
		}

		.owl-dot {
			margin-top: 30px;
		}
	}

	@include media-breakpoint-up(xxl) {
		.owl-dot {
			margin-top: 60px;
		}
	}
}

.owl-no-nav {
	.owl-nav {
		display: none;
	}
}

.owl-carousel + .button-twitter {
	@include media-breakpoint-up(xxl) {
		margin-top: 90px;
	}
}

// owl layout 5
.owl-layout-5 {
	.owl-dots {
		@include media-breakpoint-up(md) {
			text-align: left;
		}

		@include media-breakpoint-up(xxl) {
			margin-top: 85px;
		}
	}
}

.owl-carousel[data-nav='false'] {
	.owl-nav {
		display: none;
	}
}