/*
*
* 2.1  Preloader
* --------------------------------------------------
*/

// Base styles
// -------------------------


.page-loader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 9999999;
  background: $white;
  background-image: linear-gradient(380deg, #fff 50%, #c2c2c3 100%);
  transition: .3s all ease;
  
  &.loaded {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
  
  &.ending {
    display: none;
  }
  
  .page-loader-body {
    text-align: center;
    &:before {
      content: 'BRAVE THEME';
      display: block;
      margin-bottom: 26px;
      font-family: $font-family-sans-serif-3;
      font-size: 24px;
      line-height: 1;
      font-weight: 900;
      text-align: center;
      color: $gray-darker; 
    }
  }
}

@supports (-webkit-background-clip: text) {
  .page-loader {
    .page-loader-body {
      &:before {
        background: linear-gradient(to right, $primary 20%, $secondary 40%, $secondary-1 60%, $primary 80%);
        background-size: 200% auto;
        background-clip: text; 
        text-fill-color: transparent;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.ie-10,
.ie-11 {
  .page-loader {
    .page-loader-body {
      &:before {
        color: $secondary;
        background: none;
      }
    }
  }
}

[data-x-mode="true"] .page-loader {
  display: none !important;
}

// Custom Styles
// -------------------------

.preloader-wrapper {
  display: inline-block;
  font-size: 0;
  position: relative;
  width: 50px;
  height: 50px;
  
  &.small {
    width: 36px;
    height: 36px;
  }
  
  &.big {
    width: 64px;
    height: 64px;
  }
  
  &.active {
    -webkit-animation: container-rotate 1568ms linear infinite;
    animation: container-rotate 1568ms linear infinite;
  }
}

@-webkit-keyframes container-rotate {
  to { -webkit-transform: rotate(360deg) }
}

@keyframes container-rotate {
  to { transform: rotate(360deg) }
}

.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: $primary;
}

.spinner-blue,
.spinner-blue-only {
  border-color: $primary;
}

.spinner-red,
.spinner-red-only {
  border-color: $secondary;
}

.spinner-yellow,
.spinner-yellow-only {
  border-color: $secondary-1;
}

.spinner-green,
.spinner-green-only {
  border-color: $secondary-3;
}

.active .spinner-layer.spinner-blue {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both, blue-fade-in-out 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

.active .spinner-layer.spinner-red {
  /* durations: 4 * ARCTIME */
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both, red-fade-in-out 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

.active .spinner-layer.spinner-yellow {
  /* durations: 4 * ARCTIME */
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both, yellow-fade-in-out 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

.active .spinner-layer.spinner-green {
  /* durations: 4 * ARCTIME */
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both, green-fade-in-out 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

.active .spinner-layer,
.active .spinner-layer.spinner-blue-only,
.active .spinner-layer.spinner-red-only,
.active .spinner-layer.spinner-yellow-only,
.active .spinner-layer.spinner-green-only {
  /* durations: 4 * ARCTIME */
  opacity: 1;
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

@-webkit-keyframes fill-unfill-rotate {
  12.5% { -webkit-transform: rotate(135deg);  } /* 0.5 * ARCSIZE */
  25%   { -webkit-transform: rotate(270deg);  } /* 1   * ARCSIZE */
  37.5% { -webkit-transform: rotate(405deg);  } /* 1.5 * ARCSIZE */
  50%   { -webkit-transform: rotate(540deg);  } /* 2   * ARCSIZE */
  62.5% { -webkit-transform: rotate(675deg);  } /* 2.5 * ARCSIZE */
  75%   { -webkit-transform: rotate(810deg);  } /* 3   * ARCSIZE */
  87.5% { -webkit-transform: rotate(945deg);  } /* 3.5 * ARCSIZE */
  to    { -webkit-transform: rotate(1080deg); } /* 4   * ARCSIZE */
}

@keyframes fill-unfill-rotate {
  12.5% { transform: rotate(135deg);  } /* 0.5 * ARCSIZE */
  25%   { transform: rotate(270deg);  } /* 1   * ARCSIZE */
  37.5% { transform: rotate(405deg);  } /* 1.5 * ARCSIZE */
  50%   { transform: rotate(540deg);  } /* 2   * ARCSIZE */
  62.5% { transform: rotate(675deg);  } /* 2.5 * ARCSIZE */
  75%   { transform: rotate(810deg);  } /* 3   * ARCSIZE */
  87.5% { transform: rotate(945deg);  } /* 3.5 * ARCSIZE */
  to    { transform: rotate(1080deg); } /* 4   * ARCSIZE */
}

@-webkit-keyframes blue-fade-in-out {
  from { opacity: 1; }
  25% { opacity: 1; }
  26% { opacity: 0; }
  89% { opacity: 0; }
  90% { opacity: 1; }
  100% { opacity: 1; }
}

@keyframes blue-fade-in-out {
  from { opacity: 1; }
  25% { opacity: 1; }
  26% { opacity: 0; }
  89% { opacity: 0; }
  90% { opacity: 1; }
  100% { opacity: 1; }
}

@-webkit-keyframes red-fade-in-out {
  from { opacity: 0; }
  15% { opacity: 0; }
  25% { opacity: 1; }
  50% { opacity: 1; }
  51% { opacity: 0; }
}

@keyframes red-fade-in-out {
  from { opacity: 0; }
  15% { opacity: 0; }
  25% { opacity: 1; }
  50% { opacity: 1; }
  51% { opacity: 0; }
}

@-webkit-keyframes yellow-fade-in-out {
  from { opacity: 0; }
  40% { opacity: 0; }
  50% { opacity: 1; }
  75% { opacity: 1; }
  76% { opacity: 0; }
}

@keyframes yellow-fade-in-out {
  from { opacity: 0; }
  40% { opacity: 0; }
  50% { opacity: 1; }
  75% { opacity: 1; }
  76% { opacity: 0; }
}

@-webkit-keyframes green-fade-in-out {
  from { opacity: 0; }
  65% { opacity: 0; }
  75% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes green-fade-in-out {
  from { opacity: 0; }
  65% { opacity: 0; }
  75% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

/**
 * Patch the gap that appear between the two adjacent div.circle-clipper while the
 * spinner is rotating (appears on Chrome 38, Safari 7.1, and IE 11).
 */
.gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
}

.gap-patch .circle {
  width: 1000%;
  left: -450%;
}

.circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
  
  .circle {
    width: 200%;
    height: 100%;
    border-width: 2px; /* STROKEWIDTH */
    border-style: solid;
    border-color: inherit;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: none;
    animation: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }
  
  &.left .circle {
    left: 0;
    border-right-color: transparent !important;
    -webkit-transform: rotate(129deg);
    transform: rotate(129deg);
  }
  &.right .circle {
    left: -100%;
    border-left-color: transparent !important;
    -webkit-transform: rotate(-129deg);
    transform: rotate(-129deg);
  }
}


.active .circle-clipper.left .circle {
  /* duration: ARCTIME */
  -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  animation: left-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

.active .circle-clipper.right .circle {
  /* duration: ARCTIME */
  -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  animation: right-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
}

@-webkit-keyframes left-spin {
  from { -webkit-transform: rotate(130deg); }
  50% { -webkit-transform: rotate(-5deg); }
  to { -webkit-transform: rotate(130deg); }
}

@keyframes left-spin {
  from { transform: rotate(130deg); }
  50% { transform: rotate(-5deg); }
  to { transform: rotate(130deg); }
}

@-webkit-keyframes right-spin {
  from { -webkit-transform: rotate(-130deg); }
  50% { -webkit-transform: rotate(5deg); }
  to { -webkit-transform: rotate(-130deg); }
}

@keyframes right-spin {
  from { transform: rotate(-130deg); }
  50% { transform: rotate(5deg); }
  to { transform: rotate(-130deg); }
}

#spinnerContainer.cooldown {
  /* duration: SHRINK_TIME */
  -webkit-animation: container-rotate 1568ms linear infinite, fade-out 400ms cubic-bezier(0.4, 0.0, 0.2, 1);
  animation: container-rotate 1568ms linear infinite, fade-out 400ms cubic-bezier(0.4, 0.0, 0.2, 1);
}

@-webkit-keyframes fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}

@keyframes fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}
 