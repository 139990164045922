/*
*
* 2.6  Buttons
* --------------------------------------------------
*/

$sizing-xs: 9px, 28px;
$sizing-sm: 11px, 35px;
$sizing-md: 14px, 38px;
$sizing-lg: 17px, 65px;
$sizing-xl: 25px, 70px;

/**
* Button General Styles
*/
.button {
  position: relative;
  display: inline-block;
  max-width: 100%;
  padding: 9px 30px;
  min-width: 210px;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  letter-spacing: $btn-letter-spacing;
  text-transform: $btn-text-transform;
  border: $btn-border-width solid;
  border-radius: $btn-border-radius;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  transition: .25s all ease;
  white-space: pre-wrap;

  &:focus,
  &:active,
  &:active:focus {
    outline: none;
  }

  &.disabled {
    color: black;
    background-color: gray;
  }

  .caret {
    margin-left: 8px;
  }  
 
  @include media-breakpoint-up(md) {
    padding: nth($sizing-md, 1) nth($sizing-md, 2);
  }
  
  @include media-breakpoint-up(lg) {
    min-width: 230px;
  }
}

[data-x-mode='true'] .button {
  white-space: normal;
}

.button-block {
  display: block;
  width: 100%;
}

/**
* Buttons appearance style
*/

.button-default {
  @include btn-variant($gray-darker, $gray-2, $gray-2, $white, $primary, $primary);
}

.button-primary {
  @include btn-variant($white, $primary, $primary, $white, darken($primary, 5%), darken($primary, 5%));
}

.button-secondary {
  @include btn-variant($white, $secondary, $secondary, $white, $primary, $primary);
}

.button-default-outline {
  @include btn-variant($gray-darker, $white, $gray-2, $white, $secondary-2, $secondary-2);
  
  &.button-default-outline_facebook {
    &:hover {
      border-color: $facebook;
      background: $facebook;
    }
  }
   
  &.button-default-outline_twitter {
    &:hover {
      border-color: $twitter;
      background: $twitter;
    }
  }
  
  &.button-default-outline_google {
    &:hover {
      border-color: $google;
      background: $google;
    }
  }
}

// Social
// --------------
.button-facebook {
  @include btn-variant($white, $facebook, $facebook, $white, $facebook-darken, $facebook-darken);
}

.button-twitter {
  @include btn-variant($white, $twitter, $twitter, $white, $twitter-darken, $twitter-darken);
}

.button-google {
  @include btn-variant($white, $google, $google, $white, $google-darken, $google-darken);
}

.button-facebook-outline {
  @include btn-variant($facebook, transparent, $facebook, $white, $facebook, $facebook);
}

.button-twitter-outline {
  @include btn-variant($twitter, transparent, $twitter, $white, $twitter, $twitter);
}

.button-google-outline {
  @include btn-variant($google, transparent, $google, $white, $google, $google);
}

.wrap-buttons-sm {
  @media (min-width: 1160px) {
    .button-sm {
      min-width: 120px;
    }
  }
}

// Btn with shadow
.button-shadow {
  box-shadow: $shadow-area-sm;

  &:active {
    box-shadow: none;
  }

  &.button-secondary {
    box-shadow: $shadow-secondary-sm;

    &:hover {
      box-shadow: 0 6px 10px 0 rgba($primary, .36);
    }

    &:active {
      box-shadow: none;
    }
  }
}

/**
* Buttons sizes
*/

.button-xs {
  @include btn-size(nth($sizing-xs, 1), nth($sizing-xs, 2), 14px, 24px);
  min-width: 150px;
}

.button-sm {
  min-width: 170px;
  @include btn-size(nth($sizing-sm, 1) * .8, nth($sizing-sm, 2) * .8, 14px, 24px);
  
  @include media-breakpoint-up(md) {
    padding: nth($sizing-sm, 1) nth($sizing-sm, 2);
  }
  
  @include media-breakpoint-up(xl) {
    min-width: 190px;
  }
  
  @include media-breakpoint-up(xxl) {
    font-size: 16px; 
  }
}

.button-lg {
  @include btn-size(nth($sizing-lg, 1), nth($sizing-lg, 2), 18px, 22px);
}

/**
* Button Shapes
*/

.button-ellipse {
  &-md { border-radius: 4px; }

  &-lg { border-radius: 8px; }
}

.button-square {
  border-radius: 0;
}

/**
* Button effects
*/
.button-nina {
  position: relative;
  overflow: hidden;
  line-height: 1.3;
  transition: background 0.3s 0.01s, border-color 0.3s 0.01s;

  > span {
    display: none;
    vertical-align: middle;
    line-height: 24px;
  }

  .button-original-content {
    display: block;
  }
}
 
html:not(.desktop) {
  @include media-breakpoint-down(md) {
    .button-original-content {
      transition: .22s;
      transition-delay: 0s !important;
    }
  }
}

.desktop {
  @include media-breakpoint-up(lg) {
    .button-nina {
      > span {
        position: relative;
        overflow: hidden;
        display: inline-block;
        line-height: 24px;
        text-align: center;
        opacity: 0.01;
        color: inherit;
        transform: translate3d(0, -10px, 0);
        transition: 0.22s;
        transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
        
        &:first-child {
          padding-left: 4px;
        }
        
        &:nth-last-child(2) {
          padding-right: 4px;
        }
        
        &:empty {
          &::after {
            display: inline-block;
            padding: 0 2px;
            content: '\a';
          }
        }
      }
      
      > span:only-child {
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }

      .button-original-content {
        position: absolute;
        z-index: 1;
        opacity: 1;
        top: 0;
        left: 0;
        right: 0;
        display: block;
        padding: inherit;
        transform: translate3d(0, 0, 0);
        letter-spacing: $btn-letter-spacing;
        min-width: inherit;
        background-color: transparent;
      }
      
      &:hover {
        > span {
          opacity: 1;
          top: 0;
          transform: translate3d(0, 0, 0);
        }

        .button-original-content {
          opacity: 0;
          transform: translate3d(0, 100%, 0);
          transition-delay: 0s!important;
        }
      }
    }
  }
}

/**
* Button Icon styles
*/
.button-icon,
.button-icon-alternate {
  .icon {
    position: relative;
    font-size: 24px;
    vertical-align: middle;
    color: inherit;
  }

  // Button xs
  &.button-xs {
    .icon {
      font-size: 19px;
    }
  }

  // Button sm
  &.button-sm {
    .icon {
      font-size: 22px;
    }
  }
}

.button-icon {
  .icon {
    margin-top: -1px;
  }
  
  &.button-icon-left {
    .icon {
      padding-right: 5px;
    }
  }

  &.button-icon-right {
    .icon {
      margin-top: -2px;
      padding-left: 5px;
    }
  }

  // Sizes
  &.button-xs {
   
  }
}

/**
* Button Icon Alternate styles
*/

.button-icon-alternate {
  position: relative;
  z-index: 1;
  vertical-align: middle;

  .icon,
  .button-inner {
    display: inline-block;
  }

  &::before,
  .icon {
    width: 60px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -$btn-border-width;
    bottom: -$btn-border-width;
    z-index: -1;
    background: $gray-2;
    transition: .33s;
  }

  .icon {
    position: absolute;
    top: 0;
    bottom: 0;
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);
  }

  &.button {
    &:hover {
      &::before {
        width: calc(100% + #{$btn-border-width * 2});
        
        .ie-10 &,
        .ie-11 & {
          width: 102%;
        }
      }
    }
  }
  
  // Left variant
  &.button-icon-left {
    padding-left: 80px;
    padding-right: 40px;
    @include media-breakpoint-up(md) {
      padding-left: 100px;
    }
    
    &::before {
      left: -$btn-border-width;
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
    
    .icon {
      left: 0;
    }
    
    &:hover {
     &::before {
       border-top-right-radius: inherit;
       border-bottom-right-radius: inherit;
     }
    }
    
    // Sizes
    &.button-sm {
      padding-left: 80px;
      padding-right: 30px;
    }
    
    &.button-xs {
      padding-left: 60px;
      padding-right: 20px;
    }
  }
  
  // Right variant
  &.button-icon-right {
    padding-left: 40px;
    padding-right: 80px;
    @include media-breakpoint-up(md) {
      padding-right: 100px;
    }
    
    &::before {
      right: -$btn-border-width;
      border-top-right-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    .icon {
      right: 0;
    }

    &:hover {
      &::before {
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
      }
    }

    // Sizes
    &.button-sm {
      padding-left: 30px;
      padding-right: 80px;
    }
    
    &.button-xs {
      padding-left: 20px;
      padding-right: 60px;
    }
  }

  // Sizes
  &.button-sm {
    &::before,
    .icon {
      width: 50px;
    }
  }

  &.button-xs {
    &::before,
    .icon {
      width: 42px;
    }
  }

  // Colors
  &.button-secondary {
    &::before {
      background: rgba($black, .2);
    }
    
    &:hover {
      background: $secondary;
      border-color: $secondary;
    }

    &.button-shadow {
      &:hover {
        box-shadow: $shadow-secondary-sm;
      }
    }
  }
  
  &.button-default-outline {
    &:hover {
      color: $gray-darker;
      background-color: transparent;
      border-color: $gray-2;
    }
  }
  
  &.button-facebook {
    &::before { background: $facebook-darken; }
  }

  &.button-facebook-outline {
    .icon { color: $white; }
    &::before { background: $facebook; }
    
    &:hover {
      background-color: transparent;
    }
  }
  
  &.button-twitter {
    &::before { background: $twitter-darken; }
  }

  &.button-twitter-outline {
    .icon { color: $white; }
    &::before { background: $twitter; }
    
    &:hover {
      background-color: transparent;
    }
  }
  
  &.button-google {
    &::before { background: $google-darken; }
  }

  &.button-google-outline {
    .icon { color: $white; }
    &::before { background: $google; }
    
    &:hover {
      background-color: transparent;
    }
  }
}

// Overwrite default bootstrap buttons
.btn {
  padding: 10px 20px;
  border-radius: $btn-border-radius;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  letter-spacing: $btn-letter-spacing;
  text-transform: $btn-text-transform;
  transition: .22s;
}

.btn-sm {
  font-size: 12px;
  line-height: 1.2;
}

.btn-primary {
  @include btn-variant($white, $secondary, $secondary, $white, $primary, $primary);
  
  &:active:focus {
    background: $primary;
    border-color: $primary;
    box-shadow: none;
  }
}

.btn-cancel {
  border-color: $gray-light;
}
