/*
*
* Sections
* --------------------------------------------------
*/

.section-bottom {
  padding-top: 0;
  padding-bottom: 60px;
  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }
  &.section-reverse {
    padding-top: 60px;
    padding-bottom: 0;
    @include media-breakpoint-up(lg) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.section-xs { padding: 40px 0; }

.section-sm,
.section-md,
.section-lg,
.section-xl,
.section-xxl,
.section-variant-1,
.section-variant-2 {
  padding: 60px 0;
}

.section-lg-top {
  padding-top: 60px;
}

@include media-breakpoint-up(md) {
  .section-md {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section-lg { //
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section-xl { //
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section-xxl { //
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section-lg-top {
    padding-top: 80px;
  }

  .section-variant-1 {
    padding: 120px 0;
  }

  .section-variant-2 {
    padding: 80px 0;
  }
}

@include media-breakpoint-up(lg) {
  .section-xl { //
    padding-top: 120px;
    padding-bottom: 120px;
  }

}

@include media-breakpoint-up(xl) {
  .section-md {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section-lg {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .breadcrumbs-custom-wrap + .section-lg { padding-top: 100px; }

  .section-xl { //
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .breadcrumbs-custom-wrap + .section-xl { padding-top: 145px; }

  .section-xxl { //
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .section-variant-1 {
    padding: 160px 0 100px;
  }

  .section-variant-2 {
    padding: 120px 0 140px;
  }
}

@include media-breakpoint-up(xxl) {
  .section-lg {
    padding-top: 125px;
    padding-bottom: 125px;
    &.section-way-point {
      padding-top: 165px;
    }
  }

  .breadcrumbs-custom-wrap + .section-lg { padding-top: 170px; }

  .section-lg-alternative {
    padding-top: 130px;
    padding-bottom: 140px;
  }

  .section-xl { //
    padding-top: 165px;
    padding-bottom: 165px;
  }

  .breadcrumbs-custom-wrap + .section-xl { padding-top: 190px; }

  .section-xxl { //
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .section-lg-top {
    padding-top: 125px;
  }

  .section-variant-1 {
    padding: 220px 0 140px;
  }

  .section-variant-2 {
    padding: 160px 0;
  }
}

@include media-breakpoint-up (xxxl) {
  .section-xl { //
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .section-xxl { //
    padding-top: 290px;
    padding-bottom: 290px;
  }
}

/**
* Custom sections
*/
.section-wrap {
  position: relative;
  padding: 1px 0;
  overflow: hidden;
  .section-wrap-inner {
    @include display-flex;
    @include flex-wrap(wrap);
    @include responsive-offset(35px);
    width: 100%;

    > * { width: 100%; }
  }

  .section-wrap-aside {
    bottom: -1px;
  }

  .google-map {
    width: 100%;
    max-width: none;
  }

  .box-minimal {
    width: 100%;
    max-width: none;
  }

  .section-wrap-image {
    position: relative;
    top: -1px;
    overflow: hidden;
    height: 300px;

    img {
      position: absolute;
      height: auto;
      min-width: 100%;
      min-height: 100%;
      max-width: none;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -30%);
    }

    @supports (object-fit: cover) {
      img {
        top: 0;
        left: 0;
        transform: none;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .section-wrap-image {
      height: 500px;
    }

    @include media-breakpoint-down(md) {
      .box-width-small {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .section-wrap-aside {
      position: absolute;
      top: -1px;
      right: 0;
      bottom: -1px;
      height: auto;
      width: calc(50% - 190px);

      &.section-wrap-aside-custom {
        width: 50%;
      }
      .jp-video-single {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .jp-video .jp-jplayer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: auto !important;
        height: auto !important;
        img, video {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          object-fit: cover;
          object-position: center;
          width: 100% !important;
          height: 100% !important;
        }
      }
      .google-map { height: calc(100% + 1px); }
      .google-map-container {
        height: 100%;
      }

      img {
        height: 100%;
        width: 100%;
      }

      // Aside image
      &.section-wrap-image { }

      // Aside image right
      &.section-wrap-image-right {
        img {
          position: relative;
          left: auto;
          top: auto;
          transform: none;
          object-position: 90% center;
        }

        .ie-10 &,
        .ie-11 & {
          img {
            left: 90%;
            transform: translate(-90%, -50%);
          }
        }
      }

      .ie-10 &,
      .ie-11 & {
        .jp-video .jp-jplayer img,
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          width: auto;
          height: auto;
          transform: translate(-50%, -50%);
        }
        .jp-video .jp-jplayer video {
          left: 50%;
          transform: translateX(-50%);
          width: 110% !important;
          height: 110% !important;
        }
      }
    }

    .section-wrap-content {
      max-width: 620px;
    }
  }

  @include media-breakpoint-up(lg) {
    &.section-wrap-sm {
      .section-wrap-aside {
        width: 41.6%;
      }
    }

    &.section-wrap-bigger {
      .section-wrap-aside {
        width: 50%;
      }
    }

    // Sizing
    &.section-wrap-equal .section-wrap-aside {
      width: 50%;

      .section-wrap-content { max-width: 630px; }
    }
  }

  @include media-breakpoint-only(lg) {
    &.section-wrap-lg {
      .section-wrap-aside {
        position: relative;
        top: 1px;
        width: 100%;
        height: 500px;
      }

      &.section-reverse {
        .section-wrap-inner {
          @include flex-flow(row, wrap);
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {

    .section-wrap-aside {
      width: calc(50% - 160px);
      &.section-wrap-aside-custom {
        width: calc(50% - 30px);
      }
    }

    // Sizing
    &.section-wrap-bigger {
      .section-wrap-aside {
        width: calc(50% + 100px);
      }
    }

    &.section-wrap-equal {
      .section-wrap-aside {
        width: 50%;
      }
    }

    .section-wrap-content {
      max-width: 770px;
    }
  }

  @include media-breakpoint-up(xxl) {
    .section-wrap-aside {
      width: calc(50% - 327px);

      &.section-wrap-aside-custom {
        width: 50%;
      }
    }

    &.section-wrap-bigger {
      .section-wrap-aside {
        width: calc(50% + 163.33px);
      }
    }

    .section-wrap-content {
      max-width: 880px;
    }
  }

  &.section-reverse,
  &.section-md-reverse{
    [class*='section'] {
      //@each $alias, $resolution in $grid-breakpoints {
      //  @media (min-width: $resolution) {
      //    .range-#{$alias}-left {
      //      @include justify-content(flex-end);
      //    }
			//
      //    .range-#{$alias}-right {
      //      @include justify-content(flex-start);
      //    }
      //  }
      //}
    }
  }
  
  &.section-reverse {
    .section-wrap-inner {
      @include flex-flow(row-reverse, wrap-reverse); 
    }
    
    .range {
      @include justify-content(flex-end);
    }

    .section-wrap-aside {
      left: 0;
      right: auto;
    }
  }
  
  &.section-md-reverse {
    @include media-breakpoint-up(lg) {
      .section-wrap-inner {
        @include flex-flow(row-reverse, wrap-reverse);
      }

      .section-wrap-aside {
        left: 0;
        right: auto;
      }
    }
  }
}

/**
* Reverse section layouts
*/
.section-reverse {
  &.section-2-columns {
    > [class*='shell'] > .range {
      @include flex-flow(row-reverse, wrap-reverse);
    }

    //> [class*='shell'] > {
    //  @each $resolution, $alias in ($grid-breakpoints(lg) : md, $grid-breakpoints(xl) : lg, $grid-breakpoints(xxl) : xl, $grid-breakpoints(xxxl) : xxl) {
    //    @media (min-width: $resolution) {
    //      .range-#{$alias}-left {
    //        @include justify-content(flex-end);
    //      }
		//
    //      .range-#{$alias}-right {
    //        @include justify-content(flex-start);
    //      }
    //    }
    //  }
    //}

    @include media-breakpoint-up(xxl) {
      .box-wrap-1 {
        padding-right: 0;
        padding-left: 37px;
        margin-right: -80px;
        margin-left: 0;
      }

      .box-wrap-2 {
        padding-right: 70px;
        padding-left: 0;
      }
    }
  }

  .box-width-small {
    margin-left: auto;
    margin-right: 0;
  }
}

.sections-collapsable {
  [class*='section-'] + [class*='section-'] {
    padding-top: 0;
  }
}

.section-wrap-inner.section-xl {
  @include media-breakpoint-up (xxxl) {
    padding: 230px 0;
  }
}

.custom-wrap-1 {
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(md) {
    max-width: 700px;
  }

  @media (min-width: 1400px) {
    max-width: 1400px;
  }
}

.custom-wrap-2 {
  width: 100%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(md) {
    max-width: 650px;
  }

  @media (min-width: 1160px) {
    max-width: 1160px;
  }
}

// Layout 3 columns
.layout-3-columns {
  .box-minimal {
    display: inline-block;
    width: 100%;
    max-width: 375px;
  }

  .box-minimal + .box-minimal {
    margin-top: 30px;
  }

  .layout-column-image {
    text-align: center;
  }

  @include media-breakpoint-down(sm) {
    .layout-column-image {
      img {
        width: auto;
        max-height: 80vh;
      }
    }
  }

  @include media-breakpoint-down(md) {
    [class*='cjl'] {
      @include order(2);
    }

    .layout-column-image {
      @include order(1);

      img {
        width: auto;
        max-height: 40vh;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .box-minimal + .box-minimal {
      margin-top: 50px;
    }
  }

  @include media-breakpoint-up(lg) {

    [class*='col'] {
      padding-top: 40px;

      &:first-child {
        text-align: right;

        .box-minimal-header {
          @include flex-direction(row-reverse);
        }
      }
    }

    .layout-column-image {
      padding-top: 0;
      margin-bottom: -50px;
      > * {
        margin-right: -20px;
      }
    }

    @include media-breakpoint-down(lg) {
      .box-minimal {
        h6,
        .heading-6 {
          font-size: 20px;
        }
      }

      .box-minimal-icon {
        font-size: 30px;
      }

      .box-minimal-text {
        font-size: 14px;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    .box-minimal + .box-minimal {
      margin-top: 90px;
    }
  }
}

// Section Custom
.section-custom {
  position: relative;
  z-index: 1;
  @extend .section-lg; 
}

.page {
  .section-access + .section-custom {
    @include media-breakpoint-up(xl) {
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 35px;
      .form-find {
        transform: translateY(-50%); 
      }
    }
  }
}

// Section Way Point with other section
.section-pre-way-point + .section-way-point {
  @include media-breakpoint-down(lg) {
    margin-top: 90px;
  }
}

.mb-1 {
  margin-bottom: -1px;
}

.container-bigger {
  @include media-breakpoint-up(xxl) {
    max-width: 1500px;
  }
}


.container-custom {
  padding: 0;
  //padding: 0 15px;
  width: 100%;

  .gallery-item {
    width: 100%;
    max-width: 100%;

    .gallery-item-image {
      width: 100%;
    }
  }

  .container-custom-inner {
    padding: 0 15px;
  }

  [class*='coll'] [class*='section']:empty {
    min-height: 300px;
    margin-left: -15px;
    margin-right: -15px;
  }

  @include media-breakpoint-up(sm) {
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @include media-breakpoint-up(md) {
    min-height: 350px;
  }

  @include media-breakpoint-only(lg) {
    .container-custom-inner-1 {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  @include media-breakpoint-up(xl) {
    &,
    [class*='cell'] { padding: 0; }

    [class*='cell']:first-child .container-custom-inner {
      margin-left: auto;
      margin-right: 0;

      .section-reverse & {
        margin-left: 0;
        margin-right: auto;
      }
    }

    [class*='cell']:last-child .container-custom-inner {
      margin-left: 0;
      margin-right: auto;

      .section-reverse & {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  .container-custom-inner {
    [class*='section-'] {
      @include display-flex;
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(center);

      > * { width: 100%; }
    }

    @include media-breakpoint-up(xl) {
      padding: 0 40px;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 1600px / 2;
      padding: 0 125px;
    }

    @include media-breakpoint-up (xxxl) {
      max-width: 1800px / 2 + 60px;
      padding: 0 150px;
    }
  }

  .container-custom-inner-right {
    @include media-breakpoint-up(xl) {
      margin-left: 0;
      margin-right: auto;

      .section-reverse & {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }
}

html.lt-ie-10 * + .row-xxl,
* + .row-xxl {
  @include media-breakpoint-up(lg) {
    margin-top: 50px;
  }
  @include media-breakpoint-up(xl) {
    margin-top: 90px;
  }
}
html.lt-ie-10 .row-narrow,
.row-narrow {
  @include make-range($cell-spacing-narrow);

  > [class*='col'] {
    @include make-cell-spacing($cell-spacing-narrow);
  }

  > * + [class*='col'] {
    @include make-cell-spacing($cell-spacing-narrow);
  }
}
.container-fullwidth {
	width: 100%;
	@include media-breakpoint-up(xxl) {
		padding-left: 60px;
		padding-right: 60px;
	}
} 

.container-wide {
  margin-left: auto;
  margin-right: auto;
  max-width: 1800px;
}

.row-custom-bordered {
  [class*='col'] {
    border-style: solid;
    border-color: $gray-1;
    border-width: 0;
  }

  [class*='col']:not(:first-child) {
    border-width: 1px 0 0 0;
  }

  @include media-breakpoint-up(sm) {
    .col-sm-6 {
      &:nth-child(even) {
        border-width: 0 0 0 1px;
      }

      &:nth-child(odd) {
        border-width: 0;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .col-md-3 {
      &:first-child {
        border-width: 0 1px 0 1px;
      }

      &:nth-child(n + 2) {
        border-width: 0 1px 0 0;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .col-lg-3 {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  @media (max-width: 479px) {
    .col-lg-3 + .col-lg-3 {
      margin-top: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    .col-lg-3 {
      &:nth-child(n) {
        border-width: 0 0 0 1px;
      }

      &:nth-child(4n + 1) {
        border-width: 0;
      }
    }
  }
}

// Novi section
[data-x-mode="true"] .novi-section {
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10;
    height: 30px;
  }
}