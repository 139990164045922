//
// Functions
// --------------------------------------------------

@function headings() {
  @return unquote("h1, h2, h3, h4, h5, h6, [class^='heading-']");
}

// Icon function
@function char-code ( $var ) {
  @return unquote("\"\\#{$var}\"");
}